<template>
  <main class="main home-page">
    <div class="section hero-section">
      <img src="./../image/home.jpg" class="bg"/>
      <div class="wrapper">
        <div class="title big">{{ $t('WELCOME to') }}</div>
        <div class="title large">{{ $t('BUYBUFFS') }}</div>
        <div class="desc small">
          {{ $t('Browse our collection today and discover the perfect skins to unleash your true potential in Dota 2.') }}
        </div>
        <router-link to="/products/all" class="button orange">{{ $t('SHOP NOW') }}</router-link>
      </div>
    </div>
    <div class="section home-products-section">
      <div class="wrapper">
        <div class="title big title-main">
          {{ $t('DISCOVER') }}
        </div>
        <div class="desc desc-main">
          {{ $t('the perfect additions to your collection') }}
        </div>
        <SlickItem 
          v-if="finalProductsArray && finalProductsArray.length"
          :currency="currency"
          :is-auth="$parent.isAuth"
          :products="finalProductsArray" 
          :addToCartChosenItem="addToCartChosenItem"
        />
      </div>
    </div>
    <div class="section why-section">
      <div class="wrapper">
        <div class="left">
          <div class="title big">
            {{ $t('WHY CHOOSE') }}
          </div>
          <div class="title big">
            <b>BUYBUFFS</b>
          </div>
          <img src="./../image/why.png" class="img"/>
        </div>
        <div class="right">
          <div class="text">
            <div class="title regular">
              {{ $t('EXTENSIVE SELECTION') }}:
            </div>
            <div class="desc small">
              {{ $t("Dive into our vast selection of Dota 2 skins, ranging from rare and exclusive designs to timeless classics. With new arrivals regularly added to our inventory, you will always find something fresh to spice up your gaming arsenal.") }}
            </div>
          </div>
          <div class="text">
            <div class="title regular">
              {{ $t('COMPETITIVE PRICES') }}:
            </div>
            <div class="desc small">
              {{ $t('Enjoy competitive pricing on all our skins, allowing you to level up your gaming experience without breaking the bank. We believe that every player deserves access to top-tier skins, which is why we strive to offer unbeatable value for money.') }}
            </div>
          </div>
          <div class="text">
            <div class="title regular">
              {{ $t('EXCEPTIONAL CUSTOMER SERVICE') }}:
            </div>
            <div class="desc small">
              {{ $t("Have a question or need assistance? Our dedicated customer service team is here to help. Whether you need advice on choosing the perfect skin or require support with your purchase, we are always just a click away.") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section ready-section">
      <div class="wrapper">
        <div class="title big">
          <b>{{ $t('READY TO ELEVATE') }}</b>
        </div>
        <div class="desc">{{ $t('Your Dota 2 Experience?') }}</div>
        <div class="button" @click="$parent.openSignInModal">
          {{ $t("SIGN IN") }}
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import SlickItem from "../components/SlickItem.vue";

export default {
  name: "Home",
  props: ["addToCartChosenItem", "currency"],
  components: {
    SlickItem
  },
  data: function () {
    return {
      imgDomain: "",
      categoryOptions: [],
      qualitiyOptions: [],
      productList: []
    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getCategoryOptions();
  },
  computed: {
    finalProductsArray() {
      const finalArray = [];
      const includedQualities = new Set(); // To keep track of included qualities
      this.productList.forEach(product => {
        if (this.qualitiyOptions.includes(product.quality) && !includedQualities.has(product.quality)) {
          finalArray.push(product);
          includedQualities.add(product.quality);
        }
      });
      return finalArray;
    }
  },
  methods: {
    getProducts() {
      this.$http
      .get(process.env.VUE_APP_API + "items/list?limit=700&category=dota2&sort=desc")
      .then((res) => {
        this.productList = res.data.payload;
      })
      .catch(() => {
      });
    },
    getCategoryOptions() {
      this.$http.get(process.env.VUE_APP_API + 'items/filter-data?category=dota2')
      .then((res) => {
        this.categoryOptions = res.data.payload.types;
        this.qualitiyOptions = res.data.payload.qualities;
        this.getProducts();
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignIn();
        }
      })
    },
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
    addToCart(item, id) {
      this.$emit("addToCart", item, id);
    },
  },
};
</script>
